<template>
  <b-container :class="{ 'pt-3': !isSingleSite }">
    <audio class="d-none" ref="audioPreview" controls>
      <source :src="audioSrc" type="audio/mp3" />
    </audio>
    <button class="d-none" ref="playBtn" @click="playPreview">click</button>
    <b-row class="d-flex align-items-center">
      <b-col class="flex-grow-1" :md="hasFlux ? '12' : 'auto'" lg="auto">
        <HPageTitle v-if="isSingleSite">
          Playlist {{ detailBreadCrumbs }}
        </HPageTitle>
        <BreadCrumbs
          v-else
          :breadcrumbs="$route.matched"
          :detailPage="detailBreadCrumbs"
        />
      </b-col>
      <b-col
        class="d-flex align-items-center pl-0 mt-3 mt-lg-0 justify-content-start justify-content-md-end playlist-filters"
        :class="{ 'd-flex align-items-center mb-3': isSingleSite }"
        cols="auto"
        md="auto"
        lg="auto"
      >
        <div
          class="flux-selection mr-2 ml-3 ml-lg-0 w-100 w-lg-auto"
          v-if="hasFlux"
        >
          <h-select-flux
            small
            bg-white
            v-model="flux"
            v-if="streamOptions.length > 0 && player"
            placeholder="Choisir un flux..."
            :playerType="player.offer"
            :options="fluxOptions"
            @input="getPlaylist"
          ></h-select-flux>
        </div>
        <v-date-picker
          :locale="$i18n.locale"
          is-required
          mode="date"
          :max-date="new Date()"
          :attributes="attrs"
          :popover="{
            visibility: 'click',
            placement: $screens({
              default: 'bottom-end',
              sm: 'bottom-end',
              md: 'bottom-end',
              lg: 'bottom-end',
              xl: 'bottom-end',
              xxl: 'bottom-start',
            }),
          }"
          v-model="dateFilter"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <button class="playlist-date-picker" v-on="inputEvents">
              <b-icon icon="calendar4-event" class="mr-0 mr-md-2"></b-icon>
              <span class="d-none d-md-inline">{{ inputValue }} </span>
            </button>
          </template>
        </v-date-picker>
      </b-col>
    </b-row>
    <b-row :class="{ 'mt-4': !isSingleSite }" v-if="!playlistError">
      <b-col>
        <h-card p0>
          <b-row v-if="playlistLoaded && playlist.length === 0" class="py-4">
            <b-col class="text-center">{{ $t('player.noPlaylistDay') }} </b-col>
          </b-row>
          <template v-for="(playlistItem, index) in playlist">
            <PlaylistItem
              :key="index"
              @click.native="
                getItemPreview(playlistItem.idFile, playlistItem.type)
              "
              :playlistItem="playlistItem"
              :active="isPlayerItem(playlistItem.idFile)"
            />
          </template>
          <template v-if="!playlistLoaded">
            <b-row class="py-4">
              <b-col class="text-center">
                <b-spinner class="spinner mr-2"></b-spinner>
                {{ $t('player.playlistLoading') }}
              </b-col>
            </b-row>
          </template>
        </h-card>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col> <HServerError /> </b-col>
    </b-row>
    <HScrollTopBtn />
  </b-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import playerService from '@/services/playerService'
import BreadCrumbs from '@/components/player/BreadCrumbs'
import PlaylistItem from '@/components/player/PlaylistItem'

import dateFormat from '@/components/dateFormat.js'
export default {
  components: {
    BreadCrumbs,
    PlaylistItem,
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      player: null,
      dateFilter: new Date(),
      attrs: [
        {
          dot: {
            color: 'blue',
          },
          dates: new Date(),
        },
      ],
      audioSrc: null,
      currentPlayingId: null,
      flux: null,
      loadingCurrentPlaylist: true,
    }
  },
  async created() {
    await this.fetchPlaylists()
    this.getPlaylist()
  },
  mounted() {
    if (this.$refs.audioPreview) {
      let audioElem = this.$refs.audioPreview
      audioElem.addEventListener('ended', () => {
        this.audioSrc = null
        this.currentPlayingId = null
      })
    }
  },
  watch: {
    dateFilter() {
      this.getPlaylist()
    },
    selectedSite: {
      deep: true,
      handler: async function() {
        if (this.selectedSite && !this.loadingCurrentPlaylist) {
          await this.$router.push({
            path: `/players/${this.selectedSite.id}/playlist`,
            query: { player: this.selectedSite.name },
          })
          await this.fetchPlaylists()
          this.getPlaylist()
        }
      },
    },
  },
  computed: {
    skeletonOptionsPlayable() {
      return this.skeletonOptions.filter(
        (e) => e.skeletonPlayable && e.skeletonType === 20
      )
    },
    hasFlux() {
      return this.streamOptions && this.streamOptions.length > 1
    },
    detailBreadCrumbs() {
      if (this.$route && this.$route.query.player)
        return this.$route.query.player
      else return ''
    },
    ...mapState({
      playlist: (state) => state.playlist.playlist,
      playlistLoaded: (state) => state.playlist.playlistLoaded,
      playlistError: (state) => state.playlist.playlistError,
      selectedSite: (state) => state.user.selectedSite,
      streamOptions: (state) => state.musique.streams,
    }),
    ...mapGetters({
      isSingleSite: 'user/isSingleSite',
      skeletonOptions: 'musique/skeletonOptions',
    }),
    fluxOptions() {
      const concat = this.streamOptions.concat(this.skeletonOptionsPlayable)
      return concat
    },
  },
  methods: {
    async getItemPreview(idFile, type) {
      if (idFile !== this.currentPlayingId) {
        this.currentPlayingId = idFile
        const res = await playerService.getPlaylistItemAudio(idFile, type)
        if (res && res.data) {
          this.audioSrc = res.data
          this.$refs.audioPreview.load()
          this.$refs.playBtn.click()
        }
      } else {
        this.$refs.audioPreview.pause()
        this.currentPlayingId = null
        this.audioSrc = null
      }
    },
    async fetchPlaylists() {
      await this.$store.dispatch('musique/_getStreams')
      await this.$store.dispatch('musique/_getSkeletons')
      if (this.streamOptions.length === 0 && this.skeletonOptions.length > 0) {
        this.$store.dispatch('musique/_pushDefaultStream')
      }

      this.loadingCurrentPlaylist = true
      this.$store.dispatch('playlist/_playlistLoaded', false)
      this.$store.dispatch('playlist/_clearPlaylist')
      const res = await playerService.getPlayer(this.id)
      this.player = res.data

      this.$store.commit('user/SET_SELECTED_SITE', {
        id: res.data.idPlayer,
        name: res.data.name,
      })

      setTimeout(() => {
        this.loadingCurrentPlaylist = false
      }, 300)
      if (this.player.extended) {
        if (
          this.player.extended.currentStream &&
          this.player.extended.currentStream > 0
        ) {
          this.flux = this.player.extended.currentStream
        } else if (
          this.streamOptions.length &&
          this.player.extended.currentSkeleton > 0
        ) {
          this.flux = this.player.extended.currentSkeleton
        } else this.flux = 0
      }
    },
    playPreview() {
      this.$refs.audioPreview.play()
    },
    getPlaylist() {
      if (this.$refs.audioPreview) this.$refs.audioPreview.pause()
      this.audioSrc = null
      this.currentPlayingId = null
      this.$store.dispatch('playlist/_playlistLoaded', false)
      const params = {
        IdBrand: null,
        IdSite: parseInt(this.selectedSite.id),
        IdStream: this.isStreamId(this.flux) ? this.flux : -1,
        IdSkeleton: this.isSkeletonId(this.flux) ? this.flux : -1,
        Date: dateFormat.encodeServerDate(this.dateFilter),
      }
      this.$store.dispatch('playlist/_clearPlaylist')
      this.$store.dispatch('playlist/_getPlaylist', params)
    },
    isStreamId(id) {
      const isStream = this.streamOptions.find(
        (stream) => stream.idStream === id
      )
      return isStream ? true : false
    },
    isSkeletonId(id) {
      const isSkeleton = this.skeletonOptions.find(
        (skeleton) => skeleton.idSkeleton === id
      )
      return isSkeleton ? true : false
    },
    isPlayerItem(idFile) {
      return idFile === this.currentPlayingId
    },
  },
}
</script>

<style lang="scss" scoped>
.mask {
  display: none;
  transition: opacity 2s;
  z-index: 9;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba($color: #000000, $alpha: 0.3);
}
.spinner {
  height: 20px;
  width: 20px;
  border: 2px solid;
  border-right: 2px solid transparent;
  border-radius: 50%;
}
.playlist-date-picker {
  width: 150px;
  height: 40px;
  background: $h-main-text;
  color: white;
  border: 0;
  border-radius: 4px;
  &:hover,
  &:focus,
  &:active {
    background: darken($h-main-text, 10%) !important;
    box-shadow: 0 5px 7px -2px #d4d4d4;
  }
}

.flux-selection {
  min-width: 280px;
}

@media (max-width: 767.5px) {
  .playlist-date-picker {
    width: 60px !important;
  }
}
</style>
