<template>
  <div class="media-item" :class="{ 'is-playing': active }">
    <b-row class="flex-nowrap">
      <b-col cols="9" sm=" 10" md="9" class="d-flex align-items-center">
        <transition name="fade">
          <div class="media-container relative mr-2">
            <img
              :src="playlistItem.type === 1 ? coverImg : imageLoadError"
              alt="cover image"
              class="media-type"
              @error="imageLoadError"
            />
            <div class="ico d-flex">
              <b-icon
                :icon="active ? 'pause-circle-fill' : 'play-circle-fill'"
                scale="1.5"
              ></b-icon>
            </div>
          </div>
        </transition>
        <div class="name-container" ref="nameContainerRef">
          <span
            class="d-inline item-name ml-2"
            ref="itemNameRef"
            :class="{ 'text-float': isOverflowing }"
          >
            {{ playlistItem.name }}
          </span>
        </div>
      </b-col>
      <b-col
        cols="3"
        sm="2"
        md="3"
        lg="auto"
        xl="auto"
        xxl="auto"
        class="justify-content-end d-flex ml-auto align-items-center"
      >
        <!-- <h-button
          v-if="playlistItem.type === 2"
          transp
          orange
          class="no-white-space mr-3"
        >
          <b-icon icon="calendar2-week-fill" class="mr-lg-2"></b-icon>
          <span class="d-none d-lg-inline">Voir la planification</span>
        </h-button> -->
        <span class="broadcast-time">
          <b-icon icon="broadcast" class="mr-md-2"></b-icon
          ><span class="d-none d-md-inline">{{
            $t('player.broadcastAt')
          }}</span>
          {{
            addZero(playlistItem.playtime.hours) +
              'h' +
              addZero(playlistItem.playtime.minutes)
          }}
        </span>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    playlistItem: {
      Type: Object,
      required: true,
    },
    active: {
      Type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.loaded = true
    if (
      this.$refs.itemNameRef.offsetWidth >
      this.$refs.nameContainerRef.offsetWidth
    )
      this.isOverflowing = true
  },
  data() {
    return {
      loaded: false,
      isOverflowing: false,
    }
  },
  computed: {
    userToken() {
      return this.$store.state.user.token
    },
    coverImg() {
      const src = `https://fileapi.radioshop.net/getcover.aspx?idfile=${this.playlistItem.idFile}&ft=1201`
      return src
    },
  },
  methods: {
    addZero(val) {
      if (val < 10) return `0${val}`
      else return val
    },
    imageLoadError() {
      const img = require(`@/assets/svg/default-${this.itemType(
        this.playlistItem.type
      )}.svg`)

      event.target.src = img
    },
    itemType(type) {
      switch (type) {
        case 0:
          return ''
        case 1:
          return 'cover'
        case 2:
          return 'message'
        case 3:
          return 'video'
        case 4:
          return 'trigger'
        default:
          return 'cover'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.media-item {
  .media-container .ico {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  &:hover,
  &:active,
  &.is-playing {
    background: #f8f9fa;
    color: $primary;
    font-weight: 600;
    .text-float {
      position: absolute;
      display: block;
      animation: floatText 11s linear infinite;
    }
    .media-type {
      filter: brightness(0.5);
    }
    .media-container .ico {
      opacity: 1;
    }
  }
  &:hover {
    .media-type {
      filter: brightness(0.5);
    }
  }
}
.media-item {
  padding: 10px 15px;
  color: $h-main-text;
  cursor: pointer;
  transition: 0.2s;
  .col {
    align-items: center;
  }

  .broadcast-time {
    font-size: 0.95rem;
    color: #848c9c;
    font-style: italic;
    white-space: nowrap;
  }
  .media-type {
    transition: 0.3s;
    width: 40px;
    min-width: 40px;
    height: 40px;
    border-radius: 4px;
  }
  .name-container {
    width: 100%;
    height: 25px;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
  }

  .item-name {
    white-space: nowrap;
  }
}

@media screen and (min-width: 576px) {
  .broadcast-time {
    white-space: nowrap;
  }
}
</style>
