<template>
  <div>
    <div
      class="bg-white shadow-sm breadcrumbs-container py-2 px-3 d-flex"
      v-if="breadcrumbs.length > 0"
    >
      <span
        v-for="(route, index) in breadcrumbs"
        :key="index"
        class="text-truncate"
      >
        <span class="name" @click="routerLink(route.meta.name)">
          <b-icon
            v-if="index > 0"
            icon="chevron-double-right"
            class="ico mx-2"
            scale="0.8"
          ></b-icon>
          {{ route.meta.breadCrumb }}
          <span v-if="index === breadcrumbs.length - 1">
            <span v-if="detailPage">{{ detailPage }}</span>
          </span>
        </span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    breadcrumbs: Array,
    detailPage: {
      type: [String, Number],
      default: null,
    },
  },
  methods: {
    routerLink(routeName) {
      this.$router.push({ name: routeName })
    },
  },
}
</script>

<style lang="scss" scoped>
.breadcrumbs-container {
  border-left: solid 3px $primary;
  border-radius: 4px;
  font-weight: 300;
  height: 40px;
  color: #848c9c;
  span .name:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  span:last-child .name {
    font-weight: 600;
    color: $primary;
    pointer-events: none;
  }
}
</style>
